<template>
  <b-container fluid class="search-content p-100">
    <b-row class="mt-4 z-index-10000">
      <b-col>
        <b-button class="font-sfns text-right note-primary-btn" :to="{name: 'plot', params: {plotNo: this.$route.params.plotId}, query:{plan: $route.query.plan, coords: $route.query.coords, meter_sq: $route.query.meter_sq}}" pill block variant="primary">{{ $route.query.plan }}</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col class="text-right">
        <h4>ملاحظة شخصية (اختياري)</h4>
      </b-col>
    </b-row>
    <div class="note-cover mt-4">
      <textarea v-model="comment" class="text-right" placeholder="اضف ملاحظاتك هنا…."></textarea>
      <b-button :disabled="btnDisabled" @click="addFavourite" class="text-center note-sub-btn" size="lg" pill block variant="success">حفظ</b-button>
    </div>
    <success-modal :disabled="disabledModal" />
  </b-container>
</template>

<script>
import SuccessModal from '@/components/SuccessModal.vue'

export default {
  title: "القطع المفضلة",
  data() {
    return {
      height: "18px",
      comment: "",
      success: false,
      city_id: "",
      cities: []
    }
  },
  components: {
    SuccessModal
  },
  watch: {
    '$route.query'() {
      this.fetchPlot()
    },
    success(val) {
      this.success = val
    }
  },
  computed: {
    plot() {
      return this.$store.state.plan.plot
    },
    disabledModal() {
      return this.success
    },
    btnDisabled() {
      return this.comment == ""
    },
  },
  methods: {
    toggleSuccess() {
      this.success = true
    },
    fetchPlot() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          planNo: this.$route.query.plan,
          plotNo: this.$route.params.plotId
        }
      }
      this.$store.dispatch("plan/fetchPlot", payload)
      .then(() => {
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    fetchCities() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
         meta: {
           page: this.currentPage,
           paginate: this.pagination
         }
      }
      this.$store.dispatch("city/fetchCities", payload)
      .then((res) => {
        this.cities = res.data.data
        let cityIndex = this.cities.findIndex(city => Math.abs(parseFloat(city.coords.split(",")[0] - parseFloat(this.$route.query.coords.split(",")[0]))) < 0.1)
  
        if(cityIndex != -1) {
          this.city_id = this.cities[cityIndex]["id"]
          this.createNew()
        }
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    createNew() {
      let loader = this.$loading.show({opacity: 0.9})
      let payload = {
        meta: {
          plan_no: this.$route.query.plan,
          plot_no: this.$route.params.plotId,
          comment: this.comment,
          city_id: this.city_id,
          points: this.$route.query.coords,
          meter_sq: this.$route.query.meter_sq.toString()
        }
      }
      this.$store.dispatch("plan/createNewFavourite", payload)
      .then(() => {
        this.comment = ""
        this.toggleSuccess()
        loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
    addFavourite() {
      let loader = this.$loading.show({opacity: 0.9})
      let city_id = ""
      if (this.plot.city != undefined) {
        city_id = this.plot.city[0]["id"]
      }

      const payload = {
        meta: {
          plot_id: this.plot.id,
          comment: this.comment,
          points: this.$route.query.coords,
          plan_no: this.$route.query.plan,
          plot_no: this.$route.params.plotId,
          city_id: city_id,
          meter_sq: this.$route.query.meter_sq.toString()
        }
      }
      this.$store.dispatch("plan/createNewFavourite", payload)
      .then(() => {
        this.comment = ""
        this.toggleSuccess()
        loader.hide()
      })
      .catch(() => {
        this.fetchCities()
        loader.hide()
      })
    }
  },
  created() {
    this.fetchPlot()
  },
  updated() {
  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
